/*
  Primary:    #c83713
  Secondary:  #f7b32b
  Light:      #cfdbd5
  Black:      #070707
  Dark:       #333533
*/

html {
  /* background-color: #333533;
  color: #cfdbd5; */
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.navigation {
  a {
    margin-left: .25rem;
    margin-right: .25rem;
  }
}

.spinner {
  margin-top: 1rem;
}

.MuiCard-root {
  height: 100%;
}

.vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vertex {
  border: 1px solid rgba(255, 255, 0, 0.12) !important;
}

.vertexLogo {
  width: 14rem;
}

a > svg {
  padding-top: .2rem;
  margin-left: .2rem;
  width: .8rem !important;
}

.smallEventActions {
  flex-direction: column;
  a {
    margin-right: auto;
    margin-left: 0 !important;
  }
}

.cardArt {
  position: relative;
  text-align: center;
  color: white;

  img {
    height: 100%;
  }

  em {
    font-size: .75rem;
    position: absolute;
    bottom: .25rem;
    right: .5rem;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  }
}